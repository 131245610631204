import React from 'react';

const Instagram  = () => {
  return (
    <svg id="Icons" xmlns="http://www.w3.org/2000/svg" width="118.319" height="118.316" viewBox="0 0 118.319 118.316">
      <g id="Group_459" data-name="Group 459" transform="translate(0 0)">
        <path id="Path_1094" data-name="Path 1094" d="M52.833,17.325H28.724A12.139,12.139,0,0,0,16.6,29.451V53.557a12.141,12.141,0,0,0,12.126,12.13H52.833a12.141,12.141,0,0,0,12.126-12.13V29.451A12.139,12.139,0,0,0,52.833,17.325ZM40.779,54.945A13.44,13.44,0,1,1,54.219,41.5,13.455,13.455,0,0,1,40.779,54.945ZM56.791,27.757a1.742,1.742,0,1,1,1.742-1.742A1.742,1.742,0,0,1,56.791,27.757Z" transform="translate(18.381 17.654)"/>
        <path id="Path_1095" data-name="Path 1095" d="M66.479,8.046A59.158,59.158,0,1,0,125.638,67.2,59.228,59.228,0,0,0,66.479,8.046ZM93.3,79.257A14.782,14.782,0,0,1,78.533,94.022H54.424A14.782,14.782,0,0,1,39.659,79.257V55.15A14.783,14.783,0,0,1,54.424,40.385H78.533A14.783,14.783,0,0,1,93.3,55.15ZM65.059,19.246a1.319,1.319,0,0,1-1.252,1.384,47.412,47.412,0,0,0-5.918.676,1.409,1.409,0,0,1-.232.02,1.32,1.32,0,0,1-.228-2.619,50.188,50.188,0,0,1,6.245-.712A1.327,1.327,0,0,1,65.059,19.246ZM32.58,31.027c4.392-3.782,11.382-8.785,19.748-11.147a1.32,1.32,0,1,1,.718,2.54c-7.9,2.229-14.554,7-18.743,10.606a1.319,1.319,0,1,1-1.723-2Z" transform="translate(-7.319 -8.046)"/>
      </g>
    </svg>
  )
}

export default Instagram;