import React from 'react';

const Facebook  = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="118.319" height="118.318" viewBox="0 0 118.319 118.318">
      <path id="Path_1092" data-name="Path 1092" d="M67.466,8.306a59.159,59.159,0,1,0,59.16,59.159A59.228,59.228,0,0,0,67.466,8.306ZM90.733,42.234a1.324,1.324,0,0,1-.418.929,1.117,1.117,0,0,1-.952.355c-.035,0-1.918-.07-8.3,0a5.613,5.613,0,0,0-4.1,1.371,3.747,3.747,0,0,0-1.016,2.336L75.892,57.99l13.547.263a1.32,1.32,0,0,1,1.281,1.5L88.559,75.109a1.32,1.32,0,0,1-1.307,1.136H75.942l-.344,31.13a1.32,1.32,0,0,1-1.32,1.3H59a1.321,1.321,0,0,1-1.32-1.3l-.414-31.069L45.15,76.245a1.32,1.32,0,0,1-1.312-1.351L44.2,59.252a1.321,1.321,0,0,1,1.32-1.288h11.87v-10a36.437,36.437,0,0,1,.3-3.793,24.143,24.143,0,0,1,2.446-8.091,17.214,17.214,0,0,1,4.81-5.687,19.741,19.741,0,0,1,6.875-3.243,22.891,22.891,0,0,1,5.246-.834c1.668-.046,3.348,0,5.023.048l7.721.193a1.323,1.323,0,0,1,1.29,1.353ZM67.4,19.015a1.286,1.286,0,0,1,1.382,1.253,1.319,1.319,0,0,1-1.253,1.382,46.709,46.709,0,0,0-5.914.676,1.449,1.449,0,0,1-.234.02,1.32,1.32,0,0,1-.23-2.619A49.6,49.6,0,0,1,67.4,19.015ZM36.26,31.4A44.3,44.3,0,0,1,56.1,20.889a1.319,1.319,0,1,1,.626,2.562,41.655,41.655,0,0,0-18.657,9.875A1.32,1.32,0,0,1,36.26,31.4Z" transform="translate(-8.306 -8.306)"/>
    </svg>
  )
}

export default Facebook;